import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Routes from "../routes"
import Mosque from "../images/mosque.jpg"
import { useImpression } from "../analytics/utils"

const Islam = ({ location }) => {
  useImpression("Islam")
  return (
    <Layout location={location}>
      <Seo title="Islam Beliefs and Origins" description="Islam" />
      <h1>Islam</h1>
      <img
        src={Mosque}
        alt="A beautiful Islamic mosque, or place of worship."
        width="400"
        style={{ float: "right", padding: 20 }}
      />
      <h2>What is the definition of Islam?</h2>
      <p>
        <span style={{ fontWeight: "bold" }}>is·lam</span>{" "}
        <span style={{ color: "darkgray" }}>/isˈläm,izˈläm/</span>
        <br />
        Islam is the religion of Muslim people, and is a monotheistic faith. It
        is said to have been revealed to Muhammad from Allah, or God.
      </p>
      <p>
        Fundamentally, Islam is not a good foundation for truth or morality.
        Although Islam is a major world religion, the teachings of the Quran and
        the Hadith are no longer compatible with our modern morality and
        understanding.
      </p>
      <p>As we know him, the prophet Muhammad</p>
      <p>
        A fundamental concept of Islam is <i>tawhid</i>, which means
        "uniqueness" or "oneness". If we look to the Quran, we see God described
        as: "He is Allah, the one and only; Allah, the eternal, absolute; he
        begetteth not, nor is he begotten; and there is none like unto him."
      </p>
      <h2>Muhammad (Arabic: مُحمّد)</h2>
      <p>
        Muhammad is considered the founder of Islam. He was born around the year
        570 CE, in an Arabian city Mecca. Orphaned at age 6, and raised by first
        his grandfather, then his uncle. As he grew, he sometimes secluded
        himself in a cave for several nights to pray. At age 40 he claimed that
        Gabriel revealed himself to him and ultimately began preaching this
        publicly. His message consisted of complete "submission" (islām) to God,
        and that he was a prophet and messenger of God. He sent to confirm what
        Adam, Abraham, Moses, Jesus, and others have taught. Islam claims
        Muhammad is the last, final prophet of God.
      </p>
      <h2>Allah (Arabic: الله‎)</h2>
      <p>
        Allah is the Arabic word for God. Although most English speakers
        associated Allah with Islam, it actually refers to the god of all
        Abrahamic religions.
      </p>
      <h2>Sacred Texts</h2>
      <h3>The Qurʾān &mdash; Quran &mdash; Koran</h3>
      <p>
        The Quran is the sacred scripture of Islam. It is considered the
        verbatim word of God, delivered by the archangel Gabriel. It is
        fundamental to Islamic teaching.
      </p>
      <h3>The Sunnah</h3>
      <p>
        While it literally means "habitual practice", the Sunnah defined common
        law for pre-Islamic Arabs.
      </p>
      <h3>The Hadith</h3>
      <p>
        A collection of traditions containing sayings of the prophet Muhammad
        which, with accounts of his daily practice (the Sunna), constitute the
        major source of guidance for Muslims apart from the Koran.
      </p>
      <p>
        There's much more to learn and understand about Islam and it's
        teachings, origins, and impact in our world.
      </p>
      <hr />
      <p>
        Be sure to check out the{" "}
        <Link to={Routes.AGNOSTIC}>best religious debates</Link>.
      </p>
    </Layout>
  )
}

export default Islam
